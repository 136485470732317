<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="600"
            width="600"
            class="pa-5 text-left"
          >
            <h3 class="text-h5">
              <template v-if="tokenStatus === 'invalid'">
                Password Reset Unsuccessful
              </template>

              <template v-else-if="success">
                Password Reset Complete
              </template>

              <template v-else>
                Enter a New Password
              </template>
            </h3>

            <v-alert
              v-if="tokenStatus === 'invalid'"
              dense
              border="left"
              type="error"
            >
              The password reset link was invalid, possibly because it has
              already been used. Please
              <router-link
                :to="{ name: 'password_reset' }"
                class="white--text"
              >
                <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
                request a new password reset</router-link>.
            </v-alert>

            <template v-if="success">
              <v-alert
                border="top"
                type="success"
                class="my-2"
              >
                Your password has been set. You may go ahead and log in now.
              </v-alert>

              <div class="text-right">
                <v-btn :to="{ name: 'login' }">
                  Log In
                </v-btn>
              </div>
            </template>

            <template v-else>
              <template v-if="tokenStatus === 'checking'">
                Validating your password reset request

                <v-progress-circular
                  indeterminate
                  :width="1"
                  :size="16"
                />
              </template>

              <template v-else-if="tokenStatus === 'valid'">
                <form @submit.prevent="submit">
                  <v-alert
                    v-if="errors.non_field_errors"
                    dense
                    border="left"
                    type="warning"
                  >
                    {{ errors.non_field_errors[0] }}
                  </v-alert>

                  <v-text-field
                    v-model="new_password1"
                    label="New password"
                    :error-messages="errors.new_password1"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    @click:append="showPassword = !showPassword"
                  />

                  <v-text-field
                    v-model="new_password2"
                    label="Confirm new password"
                    :error-messages="errors.new_password2"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    @click:append="showPassword = !showPassword"
                  />

                  <div class="text-right">
                    <v-btn
                      type="submit"
                      color="primary"
                      class="mt-2"
                    >
                      Set Password
                      <v-progress-circular
                        v-if="submitting"
                        size="20"
                        width="2"
                        indeterminate
                        class="ml-2"
                      />
                    </v-btn>
                  </div>
                </form>
              </template>

              <template v-else-if="tokenStatus === 'error'">
                <v-alert
                  dense
                  border="left"
                  type="error"
                >
                  Unable to communicate with the server. Please check your
                  connection and try again.
                </v-alert>

                <div class="text-right">
                  <v-btn @click="checkPasswordResetLink">
                    Try Again
                  </v-btn>
                </div>
              </template>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    uidb64: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tokenStatus: 'checking',
      submitting: false,
      errors: {},
      success: false,
      new_password1: '',
      new_password2: '',
      showPassword: false,
    };
  },
  metaInfo() {
    let title;

    if (this.tokenStatus === 'invalid') {
      title = 'Password Reset Unsuccessful';
    } else if (this.success) {
      title = 'Password Reset Complete';
    } else {
      title = 'Enter a New Password';
    }

    return {
      title,
    };
  },
  created() {
    this.checkPasswordResetLink();
  },
  methods: {
    async checkPasswordResetLink() {
      this.tokenStatus = 'checking';

      const responseData = await this.api({
        url: 'users/check_password_reset_link/',
        method: 'POST',
        json: {
          uidb64: this.uidb64,
          token: this.token,
        },
      });

      if (responseData.status === 200) {
        this.tokenStatus = responseData.body.valid ? 'valid' : 'invalid';
      } else {
        this.tokenStatus = 'error';
      }
    },
    async submit() {
      this.errors = {};
      this.submitting = true;

      const responseData = await this.api({
        url: 'users/password_reset_submission/',
        method: 'POST',
        json: {
          uidb64: this.uidb64,
          token: this.token,
          new_password1: this.new_password1,
          new_password2: this.new_password2,
        },
      });

      this.submitting = false;

      if (responseData.status === 200) {
        this.success = true;
      } else if (responseData.status === 400) {
        this.errors = responseData.body;
      } else {
        this.errors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>
